















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import {email, required} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';
import {namespace} from 'vuex-class';
import User from '@/models/User.model';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixin';
import {userStoreActions} from '@/store/user.store';

const UserStore = namespace('user');

@Component({
  mixins: [validationMixin],
  validations: {
    userCopy: {
      salutation: {
        required
      },
      firstname: {
        required
      },
      lastname: {
        required
      },
      username: {
        required
      },
      email: {
        required,
        email
      }
    }
  }
})
export default class EditUserComponent extends mixins(ErrorMessageHandlerMixin) {

  @Prop({default: () => new User()})
  public user!: User;

  @UserStore.Action(userStoreActions.CREATE)
  public createUserAction!: (payload: { user: User }) => Promise<User>;

  @UserStore.Action(userStoreActions.UPDATE)
  public updateUserAction!: (payload: { user: User }) => Promise<User>;

  public userCopy: User = new User();
  public validData = false;
  public isLoading: boolean = false;

  @Watch('user', {immediate: true})
  public userChanged() {
    if (this.user) {
      this.userCopy = this.user.copy() as User;
      this.validData = !this.$v!.$invalid;
    }
  }

  /**
   * User status to select from
   */
  private salutations: { text: string, value: string } [] = [
    {text: this.$t('USER.DIALOG.MALE').toString(), value: 'm'},
    {text: this.$t('USER.DIALOG.FEMALE').toString(), value: 'w'}
  ];

  public async saveData() {
    this.$v.$touch();
    if (this.validData) {
      try {
        this.isLoading = true;
        if (!this.userCopy.id) {
          await this.createUserAction({user: this.userCopy});
        } else {
          await this.updateUserAction({user: this.userCopy});
        }
        Vue.notifySuccessSimplified('SETTINGS.USERS_VIEW.NOTIFICATIONS.SAVED_DATA');
        this.dismiss(true);
      } catch (err) {
        if (err.status === 409) { // Duplicate
          Vue.notifyErrorSimplified('USER.NOTIFICATIONS.DUPLICATE_USER_ERROR');
        } else {
          Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.SERVER_ERROR');
        }
      } finally {
        this.isLoading = false;
      }
    }
  }

  public dismiss(reloadData: boolean = false) {
    if (reloadData) {
      this.userCopy = new User();
    }

    this.$v.$reset();
    this.$emit('closeDialog', reloadData);
  }

  public checkForm(type: string) {
    this.validData = !this.$v!.$invalid;
    this.triggerValidation(type);
  }
}

